import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import injectSheet from 'react-jss';
import Block from 'composer-blocks/lib/containers/Block';
import responsiveModes from 'composer-blocks/lib/constants/ResponsiveModes';
import { setCurrentPage } from 'composer-blocks/lib/actions/site';
import BlockDropZone from './BlockDropZone';
import EditableContent from './EditableContent';
import PageTabs from './PageTabs';
import { getCurrentPageId } from 'composer-blocks/lib/helpers/pages';
import { buildFontFaces } from 'composer-blocks/lib/helpers/misc'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import '../assets/quill-css-v2.css';

const styles = {
  container: {
    width: '100%',
    minHeight: '100%',
    overflow: 'auto',
    overflowY: ({ editable }) => editable ? 'scroll' : 'auto',
    padding: ({ editable }) => editable ? 10 : 0,
    boxSizing: 'border-box',
    background: 'black',
    marginTop: ({ editable }) => editable ? 0 : '48px',
  },
  livePreview: {
    fontFamily: ({ globalFontId, accountFonts }) => !!globalFontId && globalFontId !== '0' && !!accountFonts[parseInt(globalFontId)]  ?  `${accountFonts[parseInt(globalFontId)].name}, ${accountFonts[parseInt(globalFontId)].fallback}` : 'inherit',
    background: 'white',
    margin: 'auto',
    width: ({ responsiveMode }) => responsiveModes[responsiveMode].default,
    maxWidth: '100%'
  }
};

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: { main: '#000' },
    secondary: { main: '#333' },
  },
})

class LivePreview extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.page !== this.props.match.params.page) {
      this.props.setCurrentPage(getCurrentPageId(nextProps.match.params.page, nextProps.pages, nextProps.pageOrder))
    }
  }

  renderBlock = (isNavbarBlock, isFooterBlock) => ((blockId, index) => {
    const { editable, preview, siteMaxWidth } = this.props;
    return (editable && !preview && !isNavbarBlock && !isFooterBlock) ? (
      <BlockDropZone
        key={blockId}
        index={index}
      >
        <Block
          blockId={blockId}
          index={index}
          editable
          preview={preview}
          ContentClass={EditableContent}
          siteMaxWidth={siteMaxWidth || '100%'}
          bodyBlock={!isNavbarBlock && !isFooterBlock}
        />
      </BlockDropZone>
    ) : (
      <Block
        key={blockId}
        blockId={blockId}
        index={index}
        editable={editable}
        preview={preview}
        ContentClass={EditableContent}
        siteMaxWidth={siteMaxWidth || '100%'}
        bodyBlock={!isNavbarBlock && !isFooterBlock}
      />
    );
  });

  renderNavbarBlock = this.renderBlock(true,false);
  renderMainBlock = this.renderBlock(false,false);
  renderFooterBlock = this.renderBlock(false,true);

  render() {
    const { classes, navbarBlockIds, blockIds, hideNavbar, hideFooter, footerBlockIds, editable, globalFontId, accountFonts } = this.props;

    return (
      <div className={classes.container}>
        {editable && <PageTabs />}
        {!!globalFontId && globalFontId !== '0' && globalFontId.split('-').map((fontId) => {
          if (!accountFonts[fontId]) return null;
          return (
            <div
              key={fontId}
              dangerouslySetInnerHTML={{ __html: buildFontFaces(fontId, accountFonts) }}
            />
          );
        })}
        <MuiThemeProvider theme={theme}>
          <div className={classes.livePreview}>
            {!hideNavbar && navbarBlockIds.map(this.renderNavbarBlock)}
            {editable && !hideNavbar && <BlockDropZone index={blockIds.length} isNavbarDropZone isPlaceholder />}
            {blockIds.map(this.renderMainBlock)}
            {editable && <BlockDropZone index={blockIds.length} isPlaceholder />}
            {!hideFooter && footerBlockIds.map(this.renderFooterBlock)}
            {editable && !hideFooter && <BlockDropZone index={blockIds.length} isFooterDropZone isPlaceholder />}

          </div>
        </MuiThemeProvider>
      </div>
    );
  }

}

const mapStateToProps = ({ site, pages, pageOrder, navbars, footers, globalSettings }) => ({
  pages,
  pageOrder,
  navbarBlockIds: navbars.blocks ? navbars.blocks : [],
  footerBlockIds: footers.blocks ? footers.blocks : [],
  blockIds: (site.currentPageId && pages[site.currentPageId].blocks) ? pages[site.currentPageId].blocks : [],
  hideNavbar: site.currentPageId && pages[site.currentPageId].hideNavbar,
  hideFooter: site.currentPageId && pages[site.currentPageId].hideFooter,
  responsiveMode: site.responsiveMode,
  globalFontId: globalSettings.globalFontId,
  accountFonts: site.entities.account_fonts,
  siteMaxWidth: globalSettings.siteMaxWidth,
});

const mapDispatchToProps = {
  setCurrentPage,

}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectSheet(styles)
);

export default enhance(LivePreview);
